import TimezoneResolver from "shared/timezone_resolver"
import { init, globalHandlersIntegration, extraErrorDataIntegration, replayIntegration } from "@sentry/react"
// datadogRum has side effects that don't work in ExecJS
import { datadogRum } from "@datadog/browser-rum"
import { assignWithBustedCache } from "shared/lib/utils"

import purify from "dompurify"

const errorsToIgnore = [
  // Ignored as part of legacy page that causes weird errors https://app.shortcut.com/ifttt/story/47191
  "YsAppBridge is not defined",

  // Mate Translate browser extension error
  "MtPopUpList is not defined",

  // Difficult-to-catch error from Google Geocoder API
  "GEOCODER_GEOCODE: ZERO_RESULTS",

  // Firefox/Safari private mode with no known consequences https://app.shortcut.com/ifttt/story/126427
  "No identifiers allowed directly after numeric literal",

  // Yandex error mostly from no-longer-supported 1Password in-app browser
  "window.yandexReadabilityChecker.isProbablyReaderable",

  // Telegram webview error from Headless Chrome (i.e. likely not an actual user)
  "window.TelegramWebview.post is not a function",

  // This is an error from Matomo ("a privacy-friendly Google Analytics alternative") outside of our control
  // https://developer.matomo.org/guides/form-analytics/reference/
  "Java bridge method invocation error",

  // This is a mysterious error that Textfit (used by Fittty) encounters with little or no user
  // experience ramifications. Our use of Textfit is now wrapped in an ErrorBoundary as well.
  // See https://app.shortcut.com/ifttt/story/126426 / https://github.com/IFTTT/web/pull/8562
  "Argument 1 ('element') to Window.getComputedStyle must be an instance of Element",
  "Window.getComputedStyle: Argument 1 is not an object",
  "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'",
]

const interceptUpgradeFromMobileUsers = event => {
  if (!event.target.closest("header.unified-header.signed-in a[data-intercept-mobile-users]")) {
    return
  }

  event.preventDefault()
  const paymentType = event.target.dataset["interceptMobileUsers"]

  if (paymentType === "apple") {
    window.appendFlash(
      "You currently have a Pro subscription on Apple. Please upgrade to Pro+ on your iOS device.",
      "danger"
    )
  } else if (paymentType === "google") {
    window.appendFlash(
      "You currently have a Pro subscription on Google. Please upgrade to Pro+ on your Android device.",
      "danger"
    )
  }
}

export function oneTimeSetup() {
  document.addEventListener("click", interceptUpgradeFromMobileUsers)

  TimezoneResolver()
}

const shouldDiscard = event => {
  if (
    event.type === "error" &&
    (event.view?.url?.includes("/join") || event.view?.url?.includes("/login")) &&
    event.error?.message?.includes("csp_violation")
  ) {
    return true
  }

  if (event.type === "error" && errorsToIgnore.some(error => event.error?.message?.includes(error))) {
    return true
  }

  return false
}

export function sharedSetup() {
  window.appendFlash = function (message, type = "success", callback = null, sticky = false) {
    const fadeOutMessage = (msg) => {
      msg.classList.remove("fadein")
      msg.classList.add("fadeout")
      setTimeout(() => {
        msg.style.display = "none"
        msg.remove()
      }, 500)
    }

    // Remove existing sticky flash messages
    document.querySelectorAll("#flash-messages .sticky").forEach(existingMsg => {
      fadeOutMessage(existingMsg)
    })

    let msg = document.createElement("div")
    msg.className = `flash-msg alert alert-${type} fadein`
    if (sticky) {
      msg.classList.add("sticky")
    }
    msg.append(purify.sanitize(message))

    document.querySelector("#flash-messages").appendChild(msg)

    if (!sticky) {
      setTimeout(() => {
        fadeOutMessage(msg)
        callback?.()
      }, 3000)
    }
  }

  document.querySelectorAll(".flash-msg:not(.sticky)").forEach(el => {
    setInterval(function () {
      el.classList.add("fadeout")
      setTimeout(() => {
        el.style.display = "none"
      }, 500)
    }, 3000)
  })

  // Web isn't using experiments for now. Reducing unnecessary calls until we
  // move Experiments to Graph and reimplement the solution.
  // confirmExperimentReceipts()

  // Enable JS error reporting via Sentry
  if (window.App?.sentryDSNGrizzlyClient) {
    init({
      dsn: window.App.sentryDSNGrizzlyClient,
      release: window.App.datadogVersion,
      initialScope: {
        user: { id: window.App?.user?.id },
      },
      // replaysSessionSampleRate: 0.0, // replay sample rate when no error occurs
      // replaysOnErrorSampleRate: 1.0, // replay sample rate when an error occurs
      integrations: [
        // Most Promise libraries have a global hook for capturing unhandled errors.
        // As we don't have control over what third-party libraries use, we'll
        // turn this kind of error off.
        globalHandlersIntegration({ onunhandledrejection: false }),

        // With this integration, Sentry will inject enumerable error properties
        // for every JS Object that errs, including non-Error ones.
        extraErrorDataIntegration(),

        // As configured above, record session replays to view in Sentry:
        // https://docs.sentry.io/product/explore/session-replay/web/
        // replayIntegration()
      ],
      ignoreErrors: errorsToIgnore,
    })
  }

  if (window.App?.datadogClientToken && window.App.trackSession) {
    datadogRum.init({
      applicationId: window.App.datadogApplicationId,
      clientToken: window.App.datadogClientToken,
      site: "datadoghq.com",
      service: window.App.datadogService,

      // Specify a version number to identify the deployed version of your application in Datadog
      version: window.App.datadogVersion,
      sessionSampleRate: Number(window.App.datadogSessionSampleRate),
      sessionReplaySampleRate: Number(window.App.datadogSessionReplaySampleRate),
      allowedTracingUrls: window.App.datadogAllowedTracingUrls,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (event, context) => {
        if (shouldDiscard(event)) {
          return false
        }
        // collect a RUM resource's server timing data
        if (event.type === 'resource' && (event.resource.type === 'fetch' || event.resource.type === 'document')) {
          let serverTiming = context.performanceEntry?.serverTiming || []
          if (serverTiming.length > 0) {
            let serverTimingEntries = serverTiming.map(
              serverEntry => [
                serverEntry.name, {"duration": serverEntry.duration, "description": serverEntry.description}
              ]
            )
            event.context.serverTiming = Object.fromEntries(serverTimingEntries)
          }
        }
      },
      proxy: options => `/dri${options.path}/p?${options.parameters}`,
    })

    datadogRum.startSessionReplayRecording()
    if (window.App.user) {
      let user = window.App.user
      if (!user.id) {
        user.id = window.App.browserSessionId
      }
      if (!user.tier) {
        user.tier = "free"
      }
      datadogRum.setUser(window.App.user)
    }
  }

  // Scrub cache-busting params from the user-facing URL
  // Example: https://github.com/IFTTT/web/blob/39386734262b01ab05386263142337e097d1b93e/app/assets/javascripts/app/components/new_user_intake/upsell.tsx#L70
  const url = new URL(window.location.href)
  const cacheBustingParams = ["🧂", "_cb"]
  const urlHasParamToRemove = cacheBustingParams.some(param => url.searchParams.has(param))

  if (urlHasParamToRemove) {
    cacheBustingParams.forEach(param => {
      if (url.searchParams.has(param)) {
        url.searchParams.delete(param)
      }
    })

    window.history.replaceState({}, "", url.pathname + url.search)
  }

  if (window.App?.user?.segment !== "unauthenticated") {
    document.querySelectorAll(`a[href='${window.location.origin}/explore']`).forEach(link => {
      link.addEventListener("click", event => {
        event.preventDefault()
        assignWithBustedCache(event.target.href, event.metaKey)
      })
    })
  }

  window.addEventListener("offline", () => {
    window.appendFlash("Your network connection has been lost.", "danger", null, true)
  })

  window.addEventListener("online", () => {
    window.appendFlash("Your network connection has been restored.", "success")
  })
}

// if using this function inside a prerendered component invoke it using `window.App.Utils`
export function logCustomDatadogAction(actionName, attributes = {}) {
  if (window.App.allowDebugErrorMessages) console.log("Logging to Datadog", actionName, attributes)
  setTimeout(() => { datadogRum.addAction(actionName, attributes) })
}

export function logDatadogError(error) {
  if (window.App.allowDebugErrorMessages) console.warn("Logging error to Datadog", logDatadogError)
  setTimeout(() => { datadogRum.addError(error) })
}
